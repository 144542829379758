//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ConfirmPlaugin4',
  props: {
    buttonSubmit: {
      type: String,
      default: '确认'
    },
    buttonClose: {
      type: String,
      default: '取消'
    },
    title: {
      type: String,
      default: '温馨提示'
    }
  },
  data () {
    return {
      type: 'error',
      // buttonSubmit: '确认',
      // buttonClose: '取消',
      // title: '温馨提示',
      dialogVisible: false
    }
  },
  methods: {
    open () {
      this.dialogVisible = true
    },

    submit (bol) {
      if (bol) {
        this.$emit('submit')
        // this.submit()
      } else {
        this.$emit('reset')
      }
      // this.dialogVisible = false
      // this.reset()
    },
    close () {
      this.dialogVisible = false
    }
  },
  mounted () {},
  destroyed () {

  }
}
