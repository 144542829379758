const globalMixins = {
  data() {
    return {}
  },
  methods: {
    //弹出登录框
    Mixin_loginIndex() {
      let data = {
        callBack: function () {
          this.$store.dispatch('setUser')
        }.bind(this)
      };
      this.$_login(data)
    },

    //弹框关闭,重置表单
    Mixin_dialogClose(formName, Dialog) {
      this[Dialog] = false;
      setTimeout(() => {
        this.$refs[formName] && this.$refs[formName].resetFields();
      }, 100);
    },
    /* 时间戳转换 */
    Mixin_time(time) {
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1 // js从0开始取
      let date1 = date.getDate()
      return year + '-' + month + '-' + date1
    },
  }
};

export {globalMixins} ;


