//
//
//
//
//
//
//
//
//

// <Title1></Title1>
export default {
  name: 'NoMessage0',
  mixins: [],
  extends: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  components: {},
  filter: {},
  watch: {},
  methods: {},
  created () {
  },
  mounted () {}
}
