//
//
//
//
//

// <Tr4></Tr4>
export default {
  name: 'Tr4',
  props: {

  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    let parantsDom = this.$refs.tr
    let parantsDomHeight = window.getComputedStyle(parantsDom).height
    let childNodes = parantsDom.childNodes

    for (let i = 0; i < childNodes.length; i++) {
      if (childNodes[i].nodeType == 1) {
        childNodes[i].style.height = parantsDomHeight
      }
    }
  },
  destroyed () {

  }
}
