//
//
//
//
//

// <Button1></Button1>
export default {
  name: 'Button1',
  props: {
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleClick (evt) {
      this.$emit('click', evt)
    }
  },
  mounted () {

  },
  destroyed () {

  }
}
