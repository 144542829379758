//
//
//
//
//
//
//

export default {
  name: 'Td3',
  props: {
    tdType: {
      type: String,
      default: 'body'
    },
    showOverflow: {
      type: Boolean,
      default: true
    },
    tdWidth: {
      type: Number
    }
  },
  computed: {

  },
  data () {
    return {
      overflow: false
    }
  },

  methods: {
    init () {
      this.$nextTick(function () {
        let td = this.$refs.td
        let height = window.getComputedStyle(td).height
        let num = height.substr(0, height.length - 2)
        if (Number(num) > 20 && this.showOverflow) {
          this.overflow = true
          td.title = td.innerHTML
        }
      }.bind(this))
    }
  },
  mounted () {

  },
  destroyed () {

  },
  updated () {

  }
}
