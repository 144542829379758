//
//
//
//
//

// <Tr1></Tr1>
export default {
  name: 'TabInner1',
  props: {
    path: {
      type: String
    }
  },
  data () {
    return {

    }
  },
  methods: {
    toPath () {
      if (this.$route.path == this.path) {
        return
      }
      // console.log(546)
      this.$router.push({ path: this.path })
    }
  },
  mounted () {
  },
  destroyed () {

  }
}
