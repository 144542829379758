//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import link from "@/public/link.js";
export default {
  name: "AuthUser",
  data() {
    return {
      isAuthed: true
    };
  },
  methods: {
    close() {
      this.$destroy(true);
    },
    toAuthUser(path) {
      // window.location.href = 'http://192.168.2.29:8082' + path
      // return
      link.toAuthUser(path);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  destroyed() {
    if (this.isAuthed) {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    }
  },

  mounted() {}
};
