import Vue from 'vue' // 引入 Vue 是因为要用到 Vue.extend() 这个方法
import Index from './index.vue' // 引入刚才的 toast 组件
import store from '@/store/index'
// console.log('挂载store', store)
let ToastConstructor = Vue.extend(Index) // 这个在前面的前置知识内容里面有讲到
let instance

const AuthUser = function (options = {}) {
  instance = new ToastConstructor({
    data: options,
    store:store(), //需要手动挂载store不然获取不到store
  }).$mount() // 渲染组件
  // document.body.appendChild(instance.$el) // 挂载到 body 下
  document.body.appendChild(instance.$el)
}

export default AuthUser
// Vue.extend(Main).$el
// Vue.extend(Main).$mount().$el
