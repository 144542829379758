//
//
//
//
//
//
//

// <Td4></Td4>
// 标题 150 内容 307
export default {
  name: 'Td4',
  props: {
    width: {
      type: String,
      default: '0'
    },
    tdType: {
      type: String,
      default: 'body'
    },
    bgWidth: {
      type: String,
      default: '50%'
    }
  },
  computed: {
    boxWidth () {
      let width = 0
      // tdType
      // body title

      if (this.tdType === 'title') {
        if (Number(this.width) > 0) {
          width = this.width
          return width + 'px;'
        }
        return width + 'px;'
      }
      if (this.tdType === 'body') {
        if (Number(this.width) > 0) {
          width = this.width
        }
        return 'calc(' + this.bgWidth + ' - ' + width + 'px);'
      }
      // 标题 150 内容 307

      return width
    }
  },
  data () {
    return {

    }
  },

  methods: {
    // handleClick (evt) {
    //   this.$emit('click', evt)
    // }
  },
  mounted () {
    // let td = this.$refs.td
    // let height = window.getComputedStyle(td).height
    // let num = height.substr(0, height.length - 2)
    // console.log(num, 'td')
    // let tdHeight = document
    // console.log(document.querySelector('.my-td4-box-inner'))
    // my-td4-box-inner
    // document.getElementBy
  },
  destroyed () {

  }
}
