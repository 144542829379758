//
//
//
//
//
//
//
//
//

// <NoMessage2></NoMessage2>
export default {
  name: 'NoMessage2',
  mixins: [],
  extends: {},
  props: {
    msg: {
      type: String,
      default: '暂无数据'
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  components: {},
  filter: {},
  watch: {},
  methods: {},
  created () {
  },
  mounted () {}
}
