//
//
//
//
//
//
//
//

// <Tr1></Tr1> color:#666666; color66 color33
export default {
  name: 'Tr3',
  props: {
    trType: {
      type: String,
      default: 'body' // title
    }
  },
  data () {
    return {

    }
  },
  methods: {
    setWidth () {
      this.$nextTick(function () {
        let parantsDom = this.$refs.box
        let parantsDomWidth = window.getComputedStyle(parantsDom).width
        parantsDomWidth = parantsDomWidth.slice(0, -2)
        let childNodes = parantsDom.childNodes
        let num = 0
        for (let i = 0; i < childNodes.length; i++) {
          if (childNodes[i].nodeType == 1) {
            num++
          }
        }
        let allWidth = parantsDomWidth - (num * 2) // 可用宽度
        let innerWidth = Math.floor((allWidth / num).toFixed(0))// 子元素宽度
        // console.log(innerWidth)

        let childWidth = Math.floor((innerWidth / parantsDomWidth * 100))
        // console.log(innerWidth / parantsDomWidth * 100, 'childWidth')
        // let childWidth = ((Number(parantsDomWidth)) / num).toFixed(0)
        // let childWidth = (1 / num * 100).toFixed(0)
        // console.log(parantsDomWidth)

        // 计算差值
        let differenceValue = 100 - (childWidth * num)
        if (differenceValue >= num) {
          childWidth++
          differenceValue = differenceValue - num
        }
        // console.log(childWidth)
        // console.log(allWidth - (innerWidth * num))
        for (let i = 0; i < childNodes.length; i++) {
          if (childNodes[i].nodeType == 1) {
            // childNodes[i].style.width = childWidth + 'px'
            if (differenceValue > 1) {
              childNodes[i].style.width = (childWidth + 1) + '%'
              differenceValue--
            } else {
              childNodes[i].style.width = childWidth + '%'
            }
          }
        }
        this.setOverflow()
      }.bind(this))
    },
    setOverflow () {
      for (let i = 0; i < this.$children.length; i++) {
        this.$children[i].init()
      }
    }
  },
  mounted () {
    this.setWidth()
  },
  destroyed () {

  },
  updated () {
    // this.$nextTick(function () {
    //   let parantsDom = this.$refs.box
    //   let parantsDomHeight = window.getComputedStyle(parantsDom).height
    //   let childNodes = parantsDom.childNodes

    //   for (let i = 0; i < childNodes.length; i++) {
    //     if (childNodes[i].nodeType == 1) {
    //       childNodes[i].style.height = parantsDomHeight
    //     }
    //   }
    // })
  }
}
