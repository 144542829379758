//
//
//
//
//

// <Button2></Button2>

export default {
  name: 'Button2',
  props: {
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  destroyed () {

  }
}
