//
//
//
//
//
//
//

// <Tr1></Tr1> color:#666666; color66 color33
export default {
  name: 'Tr2',
  props: {
    trType: {
      type: String,
      default: 'body' // title
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  destroyed () {

  }
}
