var env = require("../../env");
import cookie from "@/storage/cookies.js";
console.log("process.env.environment", process.env.environment);
// VUE_APP_APIUSER
const usercenter_url = env[process.env.environment].VUE_APP_ADVER; // memb
import axios from "@/http";
import {syncCookieToken} from "@/utils/asyncToken"
let isFrist = true
export default async ({ app, store }) => {
  const cookieRes = app.$cookies.getAll();
  if (process.client) {
    let token = cookieRes.token;
    if (token) {
      if(isFrist){
        await syncCookieToken()
        isFrist = false
      }
      try {
        let comRes = await axios.get(
          `${usercenter_url}/authentication/userInfo`,
          {
            Headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        let code = comRes.data.code;
        if (code == 666) {
          store.commit("LOGIN_OUT_SERVER");
        }
      } catch (error) {
        store.commit("LOGIN_OUT_SERVER");
      }
    }
  }
  if (process.server) {
    try {
      let comRes = await axios.get(
        `${usercenter_url}/authentication/userInfo`,
        {
          Headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      let code = comRes.data.code;
      if (code == 666) {
        delete cookieRes.token;
      }
    } catch (error) {
      delete cookieRes.token;
    }
  }

  return cookieRes;
};

