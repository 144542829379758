//
//
//
//
//

// <Tr1></Tr1>
export default {
  name: 'TabBox2',
  props: {

  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  destroyed () {

  }
}
