//
//
//
//
//
//
//

// <Td2></Td2>
export default {
  name: 'Td2',
  props: {
    width: {
      type: String,
      default: '0'
    },
    tdType: {
      type: String,
      default: 'body'
    }
  },
  computed: {
    boxWidth () {
      let width = 0
      // tdType
      // body title

      if (Number(this.width) > 0) {
        width = this.width
        return width
      }

      return 150
    }
  },
  data () {
    return {
      overflow: false
    }
  },

  methods: {
    // handleClick (evt) {
    //   this.$emit('click', evt)
    // }
  },
  mounted () {
    let td = this.$refs.td
    let height = window.getComputedStyle(td).height
    let num = height.substr(0, height.length - 2)
    // console.log(td.innerHTML)

    if (Number(num) > 20) {
      this.overflow = true
      td.title = td.innerHTML
    }
  },
  destroyed () {

  }
}
