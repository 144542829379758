//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'toast',
  data () {
    return {
      type: 'success',
      msg: '',
      time: 3000
    }
  },
  mounted () {
    // setTimeout(() => {
    // 3s 后通过父级移除子元素的方式来移除该组件实例和 DOM 节点
    //   this.$destroy(true)
    //   this.$el.parentNode.removeChild(this.$el)

    // }, this.duration)
    let that = this
    setTimeout(() => {
      that.$destroy(true)
      that.$el.parentNode.removeChild(this.$el)
    }, that.time)
  }
}
