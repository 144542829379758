//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BigImage1',
  props: {

  },
  data () {
    return {
      url: '',
      msg: {
        title: '图片'
      },
      dialogVisible: true,
      callBack: function () {

      }
    }
  },
  methods: {
    close (bol) {
      this.dialogVisible = false
      if (bol) {
        this.callBack()
      }
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  mounted () {},
  destroyed () {
    if (this.dialogVisible) {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
