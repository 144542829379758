//
//
//
//
//

// <Button1></Button1>
export default {
  name: 'Button6',
  props: {
    nativeType: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleClick (evt) {
      if (this.disabled) {
        return
      }
      this.$emit('click', evt)
    }
  },
  mounted () {

  },
  destroyed () {

  }
}
