//
//
//
//
//

// <Button5></Button5>

export default {
  name: 'Button5',
  props: {
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleClick (evt) {
      this.$emit('click', evt)
    }
  },
  mounted () {

  },
  destroyed () {

  }
}
