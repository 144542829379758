import Vue from 'vue' // 引入 Vue 是因为要用到 Vue.extend() 这个方法
import Index from './Index.vue' // 引入刚才的 toast 组件

let ToastConstructor = Vue.extend(Index) // 这个在前面的前置知识内容里面有讲到
let instance

const BigImage1 = function (options = {}) {
  instance = new ToastConstructor({
    data: options
  }).$mount() // 渲染组件
  // document.body.appendChild(instance.$el) // 挂载到 body 下
  document.body.appendChild(instance.$el)
}

export default BigImage1
// Vue.extend(Main).$el
// Vue.extend(Main).$mount().$el
