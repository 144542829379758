//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//  <!-- success error -->

// let data = {
//   type: 'error',
//   buttonSubmit: '确认',
//   buttonClose: '取消',
//   msg: {
//     title: '提示',
//   },
// }
// this.$_popout1(data)
export default {
  name: 'ConfirmPlaugin2',
  props: {

  },
  data () {
    return {
      type: 'error',
      buttonSubmit: '确认',
      buttonClose: '取消',
      msg: {
        title: '温馨提示'
      },
      dialogVisible: true,
      callBack: function () {

      }
    }
  },
  methods: {
    close (bol) {
      this.dialogVisible = false
      if (bol) {
        this.callBack()
      }
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  mounted () {},
  destroyed () {
    if (this.dialogVisible) {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
