//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var env = require("../../../../env");
import { syncCookieToken } from "@/utils/asyncToken";
import cookie from "@/storage/cookies";
var android = require('../img/android.png')
var ios = require('../img/ios.png')
import {getOS, getDownLoad} from '@/util/util.js'
export default {
  data() {
    return {
      showLogin: true,
      activeCode: 1,
      Authorization:
        "Basic MTE3NTI3Njk0NDMyNjAwNDczNjo2M2NkMzYwM2Q5YTc0ZTNiYmI4ZDhiOGE5NmQ3MWVlZA==",
      callBack: function() {},
      personUrl: env[process.env.environment].VUE_APP_PERSONURL,
      android,
      ios,
      getOS, 
      getDownLoad
    };
  },
  computed: {
    loginPath() {
      switch (this.activeCode) {
        case 1:
          return `${env[process.env.environment].VUE_APP_LOGINAPI}${
            env[process.env.environment].VUE_APP_AUTHURLTYPE
          }`;
          break;
        default:
          break;
      }
    }
  },
  methods: {
    closeLoginDialog() {
      this.showLogin = false;
      this.$destroy(true);
    },
    async getLoginData(data) {
      // console.log("huoqudengl ixni", data);
      const num = 14 * 24;
      let end = new Date(new Date().getTime() + num * 60 * 60 * 1000);
      cookie.cookieSet("token", data, end);
      await syncCookieToken();
      this.callBack()
      // console.log();
    }
  },
  destroyed() {
    if (!this.showLogin) {
      console.log("卸载组件");
      this.$destroy(true);
      this.showLogin = false;
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
